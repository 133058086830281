//引入
import axios from "./axios.js"; //路劲自己修改
//发送
export const blessingSubmit = (params) => {
    return axios({
        url: "home/blessing/submit",
        method: "post" ,  
        data: params
    })
}
export const blessingList = (params) => {
    return axios({
        url: "home/blessing/message_list",
        method: "post" ,  
        data: params
    })
}                  


                   
