// 对于axios进行二次封装
import axios from "axios";
//在当前模块引入store 
// import store from '@/store'
//1、利用axios对象的方法create,去创建一个axios实例
// 2、request就是axios，只不过稍微配置一下
const requests = axios.create({
    // 配置对象
    // 基础路径,发请求时，路径会出现api
    // https://edusk.litaifushi.com/
    baseURL:'https://clo.litaifs.com/', //公司的地址
    // 代表请求时间
    timeout:5000
})
// 请求拦截器：在请求之前，请求拦截器可以检测到，在发出去之前做一些事
requests.interceptors.request.use((config)=>{
   
    return config;
});
// 响应拦截器
requests.interceptors.response.use((res)=>{  
    return res.data
    },(error)=>{
        // 失败的回调函数
        return Promise.reject(new Error('faile'));
})
// 暴露
export default requests