<template>
    <div class="phone">
      <div class="send_button flex">
        <div class="input_bottom flex">
          <input type="text" id="inputId" placeholder="请输入祝福语" />
          <div class="fsimg" @click="fabu">
            <img src="../assets/21.png" alt srcset />
          </div>
        </div>
      </div>
      <!-- 成功提示 -->
      <transition name="fade">
        <div v-if="show" class="succer_img">
          <img src="../assets/12.png" alt srcset />
        </div>
      </transition>
    </div>
</template>
<script>
import { blessingSubmit } from "../api/home.js";
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    fabu() {
      var inputVal = document.getElementById("inputId").value;
      if (inputVal) {
        blessingSubmit({ content: inputVal }).then(res => {
        if (res.code == 200) {
          this.show = true;
          document.getElementById("inputId").value = "";
          setTimeout(() => {
            this.show = false;
          },2000);
        }
      });
      }else{
        console.log('请输入')
      }
    }
  }
};
</script>
<style scoped  lang="less">
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone {
  width: 500px;
  height: 100vh;
  background-color: #efe6b5;
  margin: auto;
  position: relative;
  background-image: url("../assets/46.jpg"); /* 设置图片路径 */
  background-size: 100%; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图片不重复 */
  background-position: center; /* 背景图片居中 */
  .send_button {
      width: 100%;
      height: 110px;
      background: radial-gradient(circle, #efe6b5 0%, #bb975a 100%);
      position: absolute;
      left: 0;
      bottom: 0;
      input {
        height: 47px;
        width: 370px;
        outline: none;
        border: 1px solid #870827;
        border-radius: 7px;
        position: relative;
        padding: 0 10px;
      }
      .fsimg {
        position: absolute;
        top: 18px;
        right: 27px;
        width: 92px;
        height: 65px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
}

.fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  .succer_img {
    position: absolute;
    left: 5%;
    top: 35%;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 35vh;
    img {
      width: 100%;
    }
  }
@media (max-width: 768px) {
  .phone {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-image: url("../assets/46.jpg"); /* 设置图片路径 */
    background-size: cover; /* 背景图片覆盖整个元素 */
    background-repeat: no-repeat; /* 背景图片不重复 */
    background-position: center; /* 背景图片居中 */
    .send_button {
      width: 100%;
      height: 110px;
      background: radial-gradient(circle, #efe6b5 0%, #bb975a 100%);
      position: absolute;
      left: 0;
      bottom: 0;
      input {
        height: 43px;
        width: 70vw;
        outline: none;
        border: 1px solid #870827;
        border-radius: 7px;
        position: relative;
        padding: 0 10px;
      }
      .fsimg {
        position: absolute;
        top: 20px;
        right: 25px;
        width: 80px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  .succer_img {
    position: absolute;
    left: 10%;
    top: 37.5%;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 215px;
    img {
      width: 100%;
    }
  }
}
</style>