<template>
  <div class="content" :style="{width: fullWidth+'px', height:fullHeight+'px'}">
    <div class="whole">
      <!-- 左边 -->
      <div class="box">
        <div class="scroll-container" ref="scrollContainer">
          <div class="scroll-content" :style="{ transform: `translateY(-${scrollTop}px)` }">
            <div v-for="(item, index) in repeatedItems" :key="index" class="item" ref="item">
              <img v-if=" index%6== 1" src="../assets/2.png" alt srcset class="itemline_img" />
              <img v-if="index%6== 0" src="../assets/58.png" alt srcset class="itemline_img" />
              <img v-if="index%6== 2" src="../assets/06.png" alt srcset class="itemline_img" />
              <img v-if="index%6 == 3" src="../assets/58.png" alt srcset class="itemline_img" />
              <img v-if="index%6== 4" src="../assets/35.png" alt srcset class="itemline_img" />
              <img v-if="index%6== 5" src="../assets/47.png" alt srcset class="itemline_img" />
              <img v-if="index%6 == 6" src="../assets/51.png" alt srcset class="itemline_img" />
              <div class="text">
                <div v-if="item.title.length>15" class="scrolling_text">{{item.title}}</div>
                <div v-else>{{item.title}}</div>
              </div>
            </div>
          </div>
        </div>
        <div id="image-container">
          <div>
            <img src="../assets/10.png" alt srcset class="swinging-image" />
          </div>
        </div>
      </div>
      <!-- 二维码 -->
      <div class="QR_code">
        <div class="QR_img">
          <img src="../assets/ewm.png" alt srcset />
        </div>
        <div class="size">
          扫码发送祝福
          <!-- 扫<br/>码<br/>发<br/>送<br/>祝<br/>福 -->
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped  lang="less">
.content {
  margin: auto;
}
.box {
  padding-top: 45px;
  box-sizing: border-box;
}
.whole {
  width: 100%;
  height: 100%;
  background-image: url("../assets/19.png"); /* 设置图片路径 */
  background-size: 100% 100%; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图片不重复 */
  background-position: center; /* 背景图片居中 */
  position: relative;
  .QR_code {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 44.5%;
    .QR_img {
      width: 240px;
      height: 240px;
      margin: auto;
      background-image: url("../assets/41.png"); /* 设置图片路径 */
      background-size: 100% 100%; /* 背景图片覆盖整个元素 */
      background-repeat: no-repeat; /* 背景图片不重复 */
      background-position: center; /* 背景图片居中 */
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 92%;
      }
    }
    .size {
      font-size: 32px;
      color: #f8a554;
      margin-left: 20px;
      writing-mode: vertical-rl; /* 文字从上到下竖排，从右到左 */
      transform: rotate(360deg); /* 可选，使文字从上到下排列 */
      letter-spacing: 3px;
    }
  }
}
.scroll-container {
  margin-left: 4%;
  width: 610px;
  height: 77vh; /* 根据需要设置 */
  overflow: hidden;
  position: relative;
  padding-left: 50px;
  box-sizing: border-box;
}

.scroll-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.item {
  height: 55px;
  border-radius: 8px;
  border: 2px solid rgb(211, 188, 133);
  color: #f8a554;
  background: linear-gradient(to bottom, #b71820, #870827, #b71820);
  line-height: 450%;
  margin: 10px 0;
  margin-bottom: 40px;
  position: relative;
}
.text {
  width: 480px;
  font-size: 32px;
  margin-left: 65px;
  line-height: 50px;
  font-weight: 500;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出的内容 */
}
.scrolling_text {
  display: inline-block;
  padding-left: 0px; /* 初始位置，确保文本从右侧开始 */
  animation: scrollText 10s linear infinite; /* 动画设置 */
}
@keyframes scrollText {
  0% {
    transform: translateX(10%); /* 从右侧开始 */
  }
  100% {
    transform: translateX(
      -80%
    ); /* 滚动到容器左侧外部，但由于循环动画，它会再次从右侧开始 */
  }
}
.itemline_img {
  width: 99px;
  position: absolute;
  top: -10px;
  left: -37px;
}
#image-container {
  position: absolute;
  top: 85vh;
  left: 5%;
  img {
    width: 90px;
  }
}

// 摇摆图片
@keyframes swing {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg); /* 调整角度以实现摇摆效果 */
  }
  75% {
    transform: rotate(-30deg); /* 调整角度以实现摇摆效果 */
  }
}
.swinging-image {
  animation: swing 1.5s infinite; /* 2秒完成一个周期，无限循环 */
  transform-origin: bottom center; /* 以底部中心为旋转点 */
}
</style>
<script>
import { blessingList } from "../api/home.js";
export default {
  data() {
    return {
      items: [
        {
          title: "1111",
          localImage: "/logo.png"
        },
        {
          title: "222",
          localImage: "/1350629.png"
        }
      ], // 示例数据
      scrollTop: 0,
      itemHeight: 0, // 假设每个 item 的高度
      containerHeight: 0, // 假设滚动容器的高度
      scrollSpeed: 1.5, // 滚动速度
      timer: null,
      listid: "", //最后一条数据的id
      size: 20,
      page: 1,
      timeset: null, //循环查询
      fullWidth: document.documentElement.clientWidth, //当前窗口的宽高
      fullHeight: document.documentElement.clientHeight
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.message();
    this.timeset = setInterval(() => {
      this.Falllisd(this.listid);
    },2500);
  },
  mounted() {
    this.containerHeight = this.$refs.scrollContainer.offsetHeight; //获取滚动容器的高度
    this.itemHeight = this.$refs.scrollContainer.querySelector(
      ".item"
    ).offsetHeight;
    this.startScroll();
  },
  computed: {
    repeatedItems() {
      return this.items.concat(this.items);
      // 重复数据以实现无缝滚动
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timeset);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //定义数据获取方法
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
      this.fullHeight = document.documentElement.clientHeight;
    },
    startScroll() {
      this.timer = setInterval(() => {
        this.scrollTop += this.scrollSpeed;
        // 滚动到底部时，跳回顶部继续滚动
        if (
          this.scrollTop >=
          this.repeatedItems.length * (this.itemHeight + 20) -
            this.containerHeight
        ) {
          this.scrollTop = 0;
        }
      }, 16); // 约等于 60fps
    },
    // 查询数据
    message() {
      blessingList({}).then(res => {
        if (res.code == 200) {
          this.items = res.data;
          if (res.data.length) {
            var lastData = res.data[res.data.length - 1];
            this.listid = lastData.id;
          }
        }
      });
    },
    // 轮询查数据
    Falllisd(listid) {
      blessingList({ id: listid }).then(res => {
        if (res.code == 200) {
          this.items = this.items.concat(res.data);
          var lastData = this.items[this.items.length - 1];
          this.listid = lastData.id;
        }
      });
    }
  }
};
</script>

 
